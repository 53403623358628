const ActivityTypes = {
  song: 'song',
  video: 'video',
  craft: 'craft',
  pictureDictionary: 'pictureDictionary',
  interactiveVideo: 'interactiveVideo',
  game: 'game',
  worksheet: 'worksheet',
  shortStory: 'shortStory',
  realTime: 'realTime',
  comic: 'comic',
  storyWithQuestions: 'storyWithQuestions',
  asArray: [
    'song',
    'video',
    'craft',
    'pictureDictionary',
    'interactiveVideo',
    'game',
    'worksheet',
    'shortStory',
    'realTime',
    'comic',
    'storyWithQuestions',
  ],
  withLabels: [
    { value: 'video', label: 'Videos', labelSingular: 'Video' },
    { value: 'game', label: 'Games', labelSingular: 'Game' },
    { value: 'song', label: 'Songs', labelSingular: 'Song' },
    { value: 'worksheet', label: 'Worksheets', labelSingular: 'Worksheet' },
    {
      value: 'pictureDictionary',
      label: 'Picture Dictionaries',
      labelSingular: 'Picture Dictionary',
    },
    {
      value: 'shortStory',
      label: 'Short Stories',
      labelSingular: 'Short Story',
    },
    { value: 'craft', label: 'Crafts', labelSingular: 'Craft' },
    {
      value: 'interactiveVideo',
      label: 'Videos with Questions',
      labelSingular: 'Video with Questions',
    },
    { value: 'realTime', label: 'Live tasks', labelSingular: 'Live task' },
    { value: 'comic', label: 'Comics', labelSingular: 'Comic' },
    {
      value: 'storyWithQuestions',
      label: 'Stories with questions',
      labelSingular: 'Story with questions',
    },
  ],
  toSlug: {
    craft: 'crafts',
    game: 'games',
    pictureDictionary: 'picture-dictionaries',
    shortStory: 'short-stories',
    song: 'songs',
    video: 'videos',
    interactiveVideo: 'interactive-videos',
    worksheet: 'worksheets',
    realTime: 'real-time',
    comic: 'comics',
    story: 'stories',
    storyWithQuestions: 'stories-with-questions',
  },
  typesOrdered: [
    'video',
    'interactiveVideo',
    'song',
    'game',
    'shortStory',
    'worksheet',
    'pictureDictionary',
    'craft',
    'realTime',
    'comic',
    'storyWithQuestions',
  ],
};

export default ActivityTypes;
