import styled, { createGlobalStyle } from 'styled-components';
import Colors from '../../../../modules/constants/Colors';
import { maxWidth, pxToVw } from '@/modules/styled/cssUnits';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';

export const StyledTopbar = styled.div`
  width: 100%;
  position: absolute;
  height: ${pxToVw(80)};
  box-shadow: ${(props) =>
    props?.transparent ? 'none' : ` 0px 1px 4px ${Colors.boxShadowBlack};`};
  display: flex;
  justify-content: center;
  background-color: ${(props) =>
    props?.transparent ? 'transparent' : 'white'};
  z-index: 1000;

  .public-page-header {
    max-width: ${maxWidth}px;
    position: absolute;
    width: 100%;
    background: ${(props) => (props?.transparent ? 'transparent' : 'white')};

    height: ${pxToVw(80)};
    display: flex;
    padding: 0px ${pxToVw(80)};
    justify-content: space-between;
    align-items: center;
    .logo {
      position: relative;
      aspect-ratio: calc(318 / 55);
      width: ${pxToVw(150)};
      display: flex;
      align-items: center;
      a {
        display: flex;
      }
    }
    .ant-menu {
      background-color: transparent;
      line-height: ${pxToVw(80)};
      justify-content: flex-end;

      &.ant-menu-horizontal {
        border-bottom: none;
      }
      .without-hover-style::after {
        border: none;
      }
      .ant-menu-item {
        padding: 0;
      }
      .ant-menu-item-selected {
        .anticon {
          color: ${Colors.primary};
        }
      }
      .anticon {
        font-size: ${pxToVw(24)};
        color: ${Colors.primary};
      }
    }
    .buttons {
      .ant-btn {
        margin-left: ${pxToVw(20)};
        margin-right: 0;
        color: ${Colors.primary};
        &.ant-btn-primary {
          color: white;
        }
        &.ant-btn-background-ghost.ant-btn-primary {
          color: ${Colors.primary};
        }
      }
    }
  }
  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    .public-page-header {
      height: 60px;

      padding: ${pxToVw(30)} ${pxToVw(40)};
      .logo {
        width: ${pxToVw(300)};
      }
      .ant-menu {
        line-height: ${pxToVw(80)};
        .anticon {
          font-size: ${pxToVw(42)};
          color: ${Colors.primary};
        }
      }
      .buttons {
        .ant-btn {
          padding-left: ${pxToVw(30)};
          color: ${Colors.primary};
          &.ant-btn-primary {
            color: white;
          }
          &.ant-btn-background-ghost.ant-btn-primary {
            color: ${Colors.primary};
          }
        }
      }
    }
  }
`;

export const MenuItemStyle = createGlobalStyle`
  .ant-menu-submenu-popup {
    .ant-menu-sub {
      padding-top: ${pxToVw(20)};
      .ant-menu-item {
        text-align: center;
        &:last-child {
          height: ${pxToVw(90)};
        }
        .anticon {
          font-size: ${pxToVw(26)};
          color: ${Colors.gray};
        }
        .buttons {
          display: flex;
          flex-direction: column;
          .ant-btn:last-child {
            margin-top: ${pxToVw(10)};
          }
        }
      }
    }
  }
`;
