import { MenuOutlined } from '@ant-design/icons';
import { Menu, Layout } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { MenuItemStyle, StyledTopbar } from './Topbar.styled';
import useTopbar from './useTopbar.hook';
import Button from '@/shared/Button/Button';
const { Header } = Layout;

export default function Topbar({ transparent, menuVisible, toggleMenu }) {
  const { state } = useTopbar();

  return (
    <StyledTopbar transparent={transparent}>
      <MenuItemStyle />
      <Header className="public-page-header">
        <div className="logo">
          <Link href="/">
            <a>
              <Image
                src={
                  process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
                  '/img/rockaLogo.png'
                }
                alt="Rockalingua logo"
                layout="fill"
              />
            </a>
          </Link>
        </div>
        <div className="buttons">
          {state.isTeacher || state.isStudent || state.isAdmin ? (
            <>
              {state.isTeacher && (
                <Link href="/dashboard" passHref>
                  <Button type="link">Go to dashboard</Button>
                </Link>
              )}
              {state.isStudent && (
                <Link href="/student/dashboard" passHref>
                  <Button type="link">Go to dashboard</Button>
                </Link>
              )}
              {state.isAdmin && (
                <Link href="/admin/dashboard" passHref>
                  <Button type="link">Go to dashboard</Button>
                </Link>
              )}
              <Link href="/logout" passHref>
                <Button type="primary" ghost>
                  Log out
                </Button>
              </Link>
            </>
          ) : (
            <>
              {state.showSignUp && (
                <Link href="/sign-up" passHref>
                  <Button type="important">Sign up</Button>
                </Link>
              )}
              {state.showLogin && (
                <Link href="/login" passHref>
                  <Button type="link">Login</Button>
                </Link>
              )}
            </>
          )}
          <Button
            type="link"
            onClick={toggleMenu}
            icon={<MenuOutlined />}
            className="ml"
            size="large"
          />
        </div>
      </Header>
    </StyledTopbar>
  );
}
