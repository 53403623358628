import { Layout } from 'antd';
import { StyledPublicPage as DefaultStyledPublicPage } from './PublicPage.styled';
import Topbar from './Topbar/Topbar';
import usePublicPage from './usePublicPage.hook';
import PublicPageFooter from './PublicPageFooter/PublicPageFooter';
import { useRouter } from 'next/router';
import Version from '../Version/Version';
import MenuDrawer from './MenuDrawer/MenuDrawer';

const { Content } = Layout;

export default function PublicPage({
  title,
  children,
  noPadding,
  withFooter,
  noMaxWidth,
  StyledPublicPage = DefaultStyledPublicPage,
}) {
  const router = useRouter();
  const { state, actions } = usePublicPage(router);

  return (
    <StyledPublicPage
      noPadding={noPadding}
      withFooter={withFooter}
      noMaxWidth={noMaxWidth}
    >
      <Topbar
        transparent={router.asPath == '/'}
        menuVisible={state.menuVisible}
        toggleMenu={actions.toggleMenu}
      />
      <Layout className="public-page-layout">
        <Content className="public-page-content">
          {title}
          {children}
        </Content>
      </Layout>
      {withFooter ? <PublicPageFooter /> : null}
      <Version />
      <MenuDrawer visible={state.menuVisible} onClose={actions.toggleMenu} />
    </StyledPublicPage>
  );
}
