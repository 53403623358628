import { CloseOutlined, InfoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { StyledVersion } from './Version.styled';
import { useState } from 'react';

const versionNumber = process.env.NEXT_PUBLIC_BUILD_VERSION;

export default function Version() {
  const [showVersion, setShowVersion] = useState(false);

  return (
    <StyledVersion>
      {showVersion ? (
        <div className="version-popup">
          <span className="version-number">
            Version: <strong>{versionNumber}</strong>
          </span>
          <Button
            onClick={() => setShowVersion(false)}
            icon={<CloseOutlined />}
            size="small"
          />
        </div>
      ) : (
        <Button
          onClick={() => setShowVersion(true)}
          icon={<InfoOutlined />}
          size="small"
          className="version-button"
        />
      )}
    </StyledVersion>
  );
}
