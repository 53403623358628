import styled from 'styled-components';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';
import { maxWidth, pxToVw } from '@/modules/styled/cssUnits';
import { PublicStyledGlobals } from 'styles/publicGlobals.styled';

export const StyledPublicPage = styled(PublicStyledGlobals)`
  --page-lateral-padding: ${pxToVw(80)};
  --page-top-padding: ${pxToVw(122)};
  --page-bottom-padding: ${pxToVw(80)};

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .public-page-layout {
    max-width: ${(props) => (props?.noMaxWidth ? '100%' : `${maxWidth}px`)};
    width: 100%;
    margin: 0;
    padding: ${(props) =>
      props?.noPadding
        ? '0px'
        : 'var(--page-top-padding) var(--page-lateral-padding) var(--page-bottom-padding)'};
    min-height: ${(props) =>
      `calc(100vh - ${pxToVw(props?.withFooter ? 490 : 0)})`};

    .public-page-content {
      display: flex;
      flex-direction: column;
    }
  }
  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    --page-lateral-padding: 20px;
    --page-top-padding: 60px;
    --page-bottom-padding: ${pxToVw(160)};
  }
`;
