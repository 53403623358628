import { useContext, useEffect } from 'react';
import { GlobalContext } from '../GlobalContext/GlobalContext';
import ActivityTypes from '@/constants/ActivityTypes';
import useCustomHook from '@/hooks/useCustomHook';

export default function usePublicPage(router) {
  const globalContext = useContext(GlobalContext);
  const { state, actions } = useCustomHook({
    defaultState: {
      menuVisible: false,
    },
    actions: {
      toggleMenu: () => {
        actions.setState({ menuVisible: !state.menuVisible });
      },
    },
  });

  useEffect(() => {
    if (
      router.isReady &&
      globalContext.state.loaded &&
      globalContext.state?.user?.student
    ) {
      if (globalContext.state.user?.student) {
        let url = '/student/dashboard';
        const activityTypeSlugs = Object.values(ActivityTypes.toSlug);
        const activityType =
          router.query.activityType ||
          activityTypeSlugs.find((activityTypeSlug) =>
            router.asPath.startsWith(`/${activityTypeSlug}`)
          );
        if (activityType) {
          url = `/student/activitiesBySlug/${activityType}`;
          if (router.query.activitySlug) {
            url += `/${router.query.activitySlug}`;
          }
        }
        router.replace(url);
        return;
      }
    }
  }, [globalContext.state, router.isReady]);

  return { state, actions };
}
