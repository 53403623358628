import { Drawer, Menu } from 'antd';
import React from 'react';
import { StyledMenuDrawer } from './MenuDrawer.styled';
import Link from 'next/link';

export default function MenuDrawer({ visible, onClose }) {
  return (
    <Drawer placement="right" onClose={onClose} visible={visible}>
      <StyledMenuDrawer>
        {menuItems.map((item) => (
          <Link key={item.path} href={item.path}>
            <a className={`menu-item ${item.className}`} onClick={onClose}>
              {item.title}
            </a>
          </Link>
        ))}
      </StyledMenuDrawer>
    </Drawer>
  );
}

const menuItems = [
  { title: 'Home', path: '/' },
  { title: 'Themes', path: '/themes' },
  { title: 'Blog', path: '/blog' },
  //   { title: 'About us', path: '/aboutUs' },
  //   { title: 'Contact us', path: '/login' },
  { title: 'Login', path: '/login' },
  { title: 'I have an enrollment link', path: '/student/enrollment' },
  { title: 'Renew subscription', path: '/sign-up', className: 'red' },
];
