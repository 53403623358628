import styled from 'styled-components';
import Colors from '@/constants/Colors';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';

export const PublicStyledGlobals = styled.div`
  --font-size-h1: 30px;
  --font-size-h2: 24px;
  --font-size-h3: 18px;
  --font-size-h4: 16px;
  --font-size-p: 16px;
  --font-size-button: 14px;
  --font-size-small: 14px;

  --line-height-h1: 38px;
  --line-height-h2: 30px;
  --line-height-h3: 28px;
  --line-height-h4: 20px;
  --line-height-p: 24px;
  --line-height-button: 24px;
  --line-height-small: 11px;

  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    --font-size-h1: 20px;
    --font-size-h2: 18px;
    --font-size-h3: 16px;

    --line-height-h1: 24px;
    --line-height-h2: 22px;
    --line-height-h3: 20px;
  }

  h1 {
    font-size: var(--font-size-h1);
    line-height: var(--line-height-h1);
    margin-bottom: 0;
    color: ${Colors.textDark};
  }
  h2 {
    font-size: var(--font-size-h2);
    line-height: var(--line-height-h2);
    font-weight: bold;
    margin-bottom: 0;
  }
  h3 {
    font-size: var(--font-size-h3);
    line-height: var(--line-height-h3);
  }
  h4 {
    font-size: var(--font-size-h4);
    line-height: var(--line-height-h4);
  }
  p {
    font-size: var(--font-size-p);
    line-height: var(--line-height-p);
  }

  ul li,
  ol li {
    font-size: var(--font-size-p);
    line-height: var(--line-height-p);
  }
`;
