import Colors from '@/constants/Colors';
import styled from 'styled-components';

export const StyledMenuDrawer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .menu-item {
    font-size: 19px;
    color: ${Colors.primary};
    font-weight: bold;

    &.red {
      color: ${Colors.purple} !important;
    }
  }
`;
