export const maxWidth = 1280;

export function pxToVw(pixels, designWidth = maxWidth) {
  // Using min() and max() esures sizes don't grow over the ones at the design width.
  return pixels != undefined
    ? pixels != null
      ? pixels > 0
        ? `min(${pixels}px, ${(pixels / designWidth) * 100}vw)`
        : `max(${pixels}px, ${(pixels / designWidth) * 100}vw)`
      : null
    : null;
}

export function pxToVwFromXCenter(pixels, designWidth = maxWidth) {
  return `calc(50vw + ${pxToVw(pixels, designWidth)} )`;
}
