import { useContext } from 'react';
import { GlobalContext } from '../../GlobalContext/GlobalContext';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useCustomHook from '@/hooks/useCustomHook';

export default function useTopbar() {
  const context = useContext(GlobalContext);
  const router = useRouter();

  const { state, actions } = useCustomHook({
    defaultState: {
      isTeacher: false,
      isStudent: false,
      isAdmin: false,
      showSignUp: true,
      showLogin: true,
    },
  });

  useEffect(() => {
    if (context.state?.loaded && router.isReady) {
      actions.setState({
        isTeacher: !!context.state?.user?.teacher,
        isStudent: !!context.state?.user?.student,
        isAdmin: !!context.state?.user?.admin,
        showSignUp: !router.asPath.includes('/sign-up'),
        showLogin:
          !router.asPath.startsWith('/login') &&
          !router.asPath.includes('/sign-up/buy') &&
          !router.asPath.includes('/sign-up/try'),
      });
    }
  }, [context.state, router.isReady]);

  return { state, actions };
}
