import Colors from '@/constants/Colors';
import styled from 'styled-components';

export const StyledVersion = styled.div`
  position: fixed;
  bottom: 6px;
  right: 6px;

  .version-button {
    color: ${Colors.backgroundPaleBlueDark};
    border-color: ${Colors.backgroundPaleBlueDark};
    transform: scale(0.7);
  }

  .version-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: ${Colors.backgroundPaleBlueDark};
    padding: 5px 10px;
    border-radius: 5px;
    color: ${Colors.textDark};
  }
`;
