import { Layout } from 'antd';
import Image from 'next/image';
import Link from 'next/link';

import { StyledPublicPageFooter } from './PublicPageFooter.styled';
import dayjs from 'dayjs';
export default function PublicPageFooter({}) {
  const { Footer } = Layout;

  return (
    <StyledPublicPageFooter>
      <Footer className="public-page-footer">
        {/* <div className="rockalingua-foundation">
          <Image
            className="main-image"
            src={'/img/world.svg'}
            alt="Rockalingua foundation"
            width={67}
            height={66}
          />
          <div className="text">
            <h2 className="title">Rockalingua Foundation</h2>
            <p>We donate 10% of all our profits to good causes.</p>
          </div>
        </div> */}
        <div className="footer-links">
          <div className="content-types">
            <h3 className="title">Content types</h3>
            <ul>
              <li>
                <Link href="/themes">
                  <a>Themes</a>
                </Link>
              </li>
              <li>
                <Link href="/videos">
                  <a>Videos</a>
                </Link>
              </li>
              <li>
                <Link href="/games">
                  <a>Games</a>
                </Link>
              </li>
              <li>
                <Link href="/songs">
                  <a>Songs</a>
                </Link>
              </li>
              <li>
                <Link href="/interactive-videos">
                  <a>Videos with questions</a>
                </Link>
              </li>
              <li>
                <Link href="/short-stories">
                  <a>Short stories</a>
                </Link>
              </li>
              <li>
                <Link href="/worksheets">
                  <a>Worksheets</a>
                </Link>
              </li>
              <li>
                <Link href="/picture-dictionaries">
                  <a>Picture dictionaries</a>
                </Link>
              </li>
              <li>
                <Link href="/crafts">
                  <a>Crafts</a>
                </Link>
              </li>
            </ul>
          </div>
          <div className="More stuff">
            <h3 className="title">More stuff</h3>
            <ul>
              {/* <li>
                <Link href="/">
                  <a>Teaher Pro info</a>
                </Link>
              </li> */}
              {/*  <li>
                <Link href="/">
                  <a>Store</a>
                </Link>
              </li> */}
              <li>
                <Link href="/blog">
                  <a>Blog</a>
                </Link>
              </li>
              {/* <li>
                <Link href="/">
                  <a>Who we are</a>
                </Link>
              </li> */}
              {/* <li>
                <Link href="/">
                  <a>Contact</a>
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="your-account">
            <h3 className="title">Your account</h3>
            <ul>
              <li>
                <Link href="/student/enrollment">
                  <a>Join a class</a>
                </Link>
              </li>
              <li>
                <Link href="/login">
                  <a>Log in</a>
                </Link>
              </li>
              <li>
                <Link href="/sign-up">
                  <a>Sign up</a>
                </Link>
              </li>
              <li>
                <Link href="/sign-up">
                  <a>Renew subscription</a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="social-media-and-legal">
          <div className="wrapper">
            <div className="logo-legal">
              <div className="logo">
                <Link href="/">
                  <a>
                    <Image
                      src={
                        process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
                        '/img/rockaLogo.png'
                      }
                      alt="Rockalingua logo"
                      layout="fill"
                    />
                  </a>
                </Link>
              </div>

              <div className="legal">
                <span className="copyright">
                  © {dayjs().year()} Rockalingua
                </span>
                <Link href="/legal/termsOfUse">
                  <a>Legal </a>
                </Link>
                <Link href="/legal/privacyPolicy">
                  <a>Privacy policy</a>
                </Link>
                <Link href="/legal/fulfillmentPolicy">
                  <a>Fulfillment policy</a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Footer>
    </StyledPublicPageFooter>
  );
}
