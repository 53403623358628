import Colors from '@/constants/Colors';
import MediaQueryBreakpoints from '@/constants/MediaQueryBreakpoints';
import { maxWidth, pxToVw } from '@/modules/styled/cssUnits';
import styled from 'styled-components';

export const StyledPublicPageFooter = styled.div`
  width: 100%;
  background-color: transparent;
  .public-page-footer {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    .rockalingua-foundation {
      width: 100%;
      background: ${Colors.primary};
      display: flex;
      justify-content: center;
      gap: ${pxToVw(20)};
      padding: ${pxToVw(20)} 0;
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          font-size: ${pxToVw(24)};
          line-height: ${pxToVw(24)};
          color: white;
          margin-bottom: 0;
        }
        p {
          color: white;
          font-size: ${pxToVw(12)};
          margin-bottom: 0;
        }
      }
    }
    .footer-links {
      width: 100%;
      max-width: ${maxWidth}px;
      flex: 1;
      padding: ${pxToVw(40)} ${pxToVw(80)} ${pxToVw(20)};

      display: grid;
      align-items: flex-start;
      grid-gap: ${pxToVw(12)};
      grid-template-columns: repeat(3, 1fr);

      .title {
        font-size: ${pxToVw(14)};
        border-bottom: 1px solid ${Colors.text};
        color: ${Colors.text};
      }
      > div {
        &.content-types {
          ul {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        ul {
          margin-top: ${pxToVw(16)};
          display: grid;
          align-items: flex-start;
          grid-gap: ${pxToVw(8)};
          grid-template-columns: repeat(1, 1fr);

          padding: 0;
          list-style: none;
          width: 100%;

          li {
            margin: 7px 0px;
            font-size: 14px;
            line-height: 16px;
          }
          a {
            color: ${Colors.primary};
          }
        }
      }
    }
    .social-media-and-legal {
      width: 100%;
      background-color: ${Colors.backgroundPaleBlue};
      display: flex;
      justify-content: center;

      .wrapper {
        padding: ${pxToVw(10)} ${pxToVw(80)};
        max-width: ${maxWidth}px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        gap: ${pxToVw(16)};

        .logo-legal {
          flex: 2;
          display: flex;
          align-items: center;
          gap: ${pxToVw(24)};

          .logo {
            aspect-ratio: calc(318 / 55);
            width: ${pxToVw(125)};

            a {
              position: relative;
              display: inline-block;
              width: 100%;
              height: 100%;

              img {
                object-fit: contain;
              }
            }
          }
          .legal {
            display: flex;
            gap: ${pxToVw(16)};
            font-size: 12px;
            color: ${Colors.primary};
          }
        }
      }
    }
  }
  @media only screen and (max-width: ${MediaQueryBreakpoints.md}) {
    .public-page-footer {
      .rockalingua-foundation {
        padding: 20px;
        gap: 20px;

        .text {
          .title {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 10px;
          }

          p {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
      .footer-links {
        .title {
          font-size: 14px;
          line-height: 20px;
        }
        > div {
          &.content-types ul {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
      .social-media-and-legal {
        .wrapper {
          padding: 25px;
          flex-direction: column;

          .logo-legal {
            width: 100%;
            align-items: flex-start;
            flex-direction: column;

            .logo {
              width: 120px;
            }

            .legal {
              font-size: 15px;
              padding: 20px 0px 10px;
              width: 100%;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
`;
